import DateFnsUtils from "@date-io/date-fns";
import { Theme, useMediaQuery } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/nextjs";
import ModalProvider from "mui-modal-provider";
import Head from "next/head";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { AppTheme } from "../AppTheme";
import { useAnalyticsContext } from "../context/AnalyticsContext";
import { useAppContext } from "../context/AppContext";
import { CommandBarContextProvider } from "../context/CommandBarContext";
import { useUserContext } from "../context/UserContext";
import { useOurRouter } from "../hooks/useOurRouter";
import { Status } from "../subpages/status";
import { QueryState } from "../types/query";
import { getLocalStorage, setLocalStorage, setSessionStorage } from "../utils/local-storage";
import { browser } from "../utils/platform";
import { useRecordHistory } from "../utils/router";
import { EasyAccessElements } from "./EasyAccessElements";

const REF_PARAM = "utm_term";
const UTM_PARAM_RE = /^utm_*/;

export const App: React.FC = ({ children }) => {
  const router = useOurRouter<{
    op: string; // used by auth
    utm_term?: string;
    utm_campaign?: string;
    utm_source?: string;
    utm_medium?: string;
    utm_content?: string;
    state?: QueryState;
    logout?: string;
    sharedHabit?: string;
    invite?: string;
  }>();

  const {
    state: { sentry },
  } = useAnalyticsContext();

  useRecordHistory(router);

  // FIXME (IW): Without this, OAuth params are borked
  // move state to new format for legacy api
  if (typeof router.query.state === "string") {
    router.query.state = JSON.parse(router.query.state || "{}");
  }

  const { state } = useAppContext();
  const [userState, userActions] = useUserContext();
  const {
    state: { segment },
  } = useAnalyticsContext();

  const [theme, setTheme] = useState<Theme>(state.theme === "dark" ? AppTheme.Dark : AppTheme.Light);
  const medium = useMediaQuery(theme.breakpoints.down("md"));

  const resizeTimeout = useRef<NodeJS.Timeout | undefined>();

  /**
   * Set the current app theme (not currently used by anything)
   */
  useEffect(() => {
    setTheme(state.theme === "dark" ? AppTheme.Dark : AppTheme.Light);
  }, [state.theme]);

  /**
   * Refresh current user on route changes
   */
  useEffect(() => {
    // Wait for router to init
    if (!router.isReady) return;

    // Record route change
    segment?.page({
      url: browser().window?.location.href,
      path: router.asPath,
      route: router.route,
      pathname: router.pathname,
      params: router.params,
    });

    // Refresh user
    if (!!router.query?.logout || ["loading", "failed"].includes(userState.status)) return;
    userActions?.load(userState.status === "ok");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, router.pathname]);

  /**
   * Hack to fix vh on mobile browsers
   */
  useEffect(() => {
    if (!browser().isBrowser) return;

    const resizeListener = () => {
      if (resizeTimeout.current) {
        clearTimeout(resizeTimeout.current);
        resizeTimeout.current = undefined;
      }

      resizeTimeout.current = setTimeout(() => {
        if (!browser().isBrowser) return;
        document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
        document.documentElement.style.setProperty("--vw", `${window.innerWidth * 0.01}px`);
      }, 25);
    };

    if (browser().isBrowser) {
      document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
      document.documentElement.style.setProperty("--vw", `${window.innerWidth * 0.01}px`);

      window.addEventListener("resize", resizeListener);
    }

    return () => {
      if (browser().isBrowser) window.removeEventListener("resize", resizeListener);
    };
  }, []);

  // FIXME (IW): This is habit-specific, move to daily habit route?
  useEffect(() => {
    if (router.query.sharedHabit) {
      try {
        setLocalStorage("habits.sharedPreset", JSON.parse(decodeURIComponent(escape(atob(router.query.sharedHabit)))));
      } catch (e) {
        console.warn("Invalid preset found", router.query.sharedHabit);
      }
    }
  }, [router.query.sharedHabit]);

  /**
   * Grab referral code to pass along in signup
   */
  useEffect(() => {
    if (!browser().isBrowser) return;

    // stash ref to add to auth state param
    const cookies = document.cookie.split(/;\s*/).reduce((acc: { [key: string]: unknown }, cookie: string) => {
      const [key, val] = cookie.split("=");
      acc[key] = val;
      return acc;
    }, {});

    const ref = cookies["ref"] || router.query?.[REF_PARAM];
    const utm = Object.entries(router.query || {})
      .filter(([k]) => UTM_PARAM_RE.test(k))
      .reduce((acc, [k, v]) => {
        const key = k.replace(/^utm_/, "");
        acc[key] = v;
        return acc;
      }, {});

    if (!!ref) setLocalStorage("auth.ref", ref);
    if (!!Object.keys(utm).length) setLocalStorage("auth.utm", utm);
  }, [router.query]);

  /**
   * Main routing logic
   * FIXME (IW): This is wayy too complicated as it is
   */
  useEffect(() => {
    // Dont do any redirect till we know what the heck is going on with the user... render will just show loading
    if (!router.isReady) return;

    // Hard reload the page after logout to make sure cookie is destroyed
    if (!!router.query.logout) {
      window.location.href = "/login";
      return;
    }

    // Wait for user to load
    if (["init", "loading"].includes(userState.status)) return;

    // TODO (IW): Move this to `protectedRoute`
    // Redirect to login if user is not authenticated
    if (
      !userState.isAuthenticated &&
      userState.status === "failed" &&
      !(
        ["", "/", "/signup", "/login", "/unauthorized", "/logout", "/offboarding", "/social", "/unsubscribe"].includes(
          router.pathname
        ) ||
        router.pathname.startsWith("/smart-onboarding") ||
        router.pathname.startsWith("/landing") ||
        // FIXME (IW): Remove at a safe time in the future (old smart 1:1 email urls)
        /^\/one-on-ones\/(\d{1,})\?/i.test(router.asPath)
      )
    ) {
      setSessionStorage("auth.redirect", router.asPath);

      void router.push({
        pathname: "/login",
      });

      return;
    }

    // legacy redirect from invite dialog to /share-reclaim
    if (!!router.query.invite) {
      void router.replace({
        pathname: "/share-reclaim",
        query: {
          ...router.query,
          invite: undefined,
        },
      });

      return;
    }

    // if we have a redirect in the state, use it, really the API should do this so its a little hacky
    // the check for "://" prevents arbitrary redirection (Open Redirect)
    const redirect = router.query.state?.redirect;
    if (!!redirect && !redirect.includes("://")) {
      void router.replace(
        {
          pathname: redirect.startsWith("/onboarding/") ? "/onboarding/[...step]" : redirect,
          query: {
            ...router.query,
            state: { ...router.query.state, redirect: undefined },
          },
        },
        redirect
      );

      return;
    }

    // redirect old smart 1:1 email urls
    // FIXME (IW): Remove at a safe time in the future
    const matches = router.asPath.match(/^\/one-on-ones\/(\d{1,})\?/i);
    if (!!matches?.length) {
      const query = { ...router.query, uid: matches[1] };
      void router.replace({
        pathname: "/smart-onboarding",
        query,
      });
    }

    // if user isn't onboarded, force them back to onboarding
    if (
      !!userState.isAuthenticated &&
      !!userState.user &&
      !userState.user.onboarded &&
      !/^\/(smart\-)?onboarding/.test(router.pathname) &&
      !router.pathname.startsWith("/team/accept")
    ) {
      void router.push({
        pathname: "/onboarding/welcome",
        query: router.query,
      });

      return;
    }

    // TODO (IW): Merge w/ logic in LoginTemplate.tsx !!
    // route logged in user to default page
    if (router.pathname === "/") {
      let pathname = "/planner";

      if (userState.user?.onboarded && !!getLocalStorage("habits.sharedPreset")) {
        pathname = "/habits";
      } else if (!userState.user?.onboarded) {
        pathname = userState.isAuthenticated ? "/onboarding/welcome" : "/login";
      }

      void router.replace({ pathname, query: router.query });
      return;
    }
  }, [medium, router, router.isReady, router.query, userState.isAuthenticated, userState.status, userState.user]);

  return (
    <ThemeProvider theme={theme}>
      <ModalProvider legacy>
        <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Head>
              <title>Reclaim – A smart friend for your calendar</title>
              <meta name="robots" content="noindex" key="robots" />
            </Head>
            <CssBaseline />
            <Box className="app">
              {/* 
                * If any of the following, just display a loading screen.
                - old state format
                - a redirect in the query
                - a route of /
                - still loading the user or trying to
                */}
              <SentryErrorBoundary
                fallback={<Status code={4319} message="Kernel Panic" />}
                showDialog
                onError={() => {
                  sentry?.addBreadcrumb({
                    category: "error",
                    message: "Kernel Panic",
                    level: sentry.Severity.Critical,
                  });
                }}
              >
                <CommandBarContextProvider>{children}</CommandBarContextProvider>
              </SentryErrorBoundary>
            </Box>
            <EasyAccessElements />
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </ModalProvider>
    </ThemeProvider>
  );
};
